import * as configs from './configs';
import getEmployeeStateTransitionPresets from './configs/trust/getEmployeeStateTransitionPresets';

const TRUST_HQS_ACTIVE = ['Amersfoort', 'Amsterdam', 'Amsterdam Loterijen', 'Assen', 'Breda', 'Den Haag', 'Eindhoven', 'Emmen', 'GDL Rotterdam', 'Groningen', 'Haarlem', 'Heerlen', 'N. Spiegelstraat - Amsterdam', 'Leiden', 'Oss', 'Rotterdam', 'Utrecht', 'Nijmegen', 'Tilburg'];

const envs = {
  default: {
    API: '/api/',
    HLR: true,
    ERROR_REPORTING: true,
    TITLE: 'DORA',
    HQS_ACTIVE: ['Amsterdam', 'Assen', 'Breda', 'Eindhoven'],
    HQS_INACTIVE: [],
    NAVIGATION_CLASS: 'bg-dark',
    APPLICANT_SUMMARY_FIELDS: ['hq', 'email', 'mobile', 'tel2', 'birth_date', 'public_transport_card', 'number_of_workdays'],
    APPLICANT_STATES: {
      New: 'Nieuw',
      'M&G planned': 'M&G gepland',
      'M&G canceled': 'M&G geannuleerd',
      'Trial planned': 'Training gepland',
      'Trial canceled': 'Training geannuleerd',
      Exit: 'Exit'
    },
    EMPLOYEE_STATES: {
      Active: 'Actief',
      Pause: 'Pauze',
      FormerEmployee: 'Oud-medewerker'
    },
    NOTIFICATION_LABELS: {
      'trial.confirmation': 'Training bevestiging',
      'mg.confirmation': 'M&G bevestiging',
      'mg.reminder': 'M&G herinnering',
      'trial.second_reminder': 'Training 2de herinnering',
      'trial.reminder': 'Training herinnering',
      'apply_confirmation': 'Welkomstmail',
      no_answer: 'Geen antwoord',
      no_show: 'Niet op komen dagen',
      'no_answer.email': 'Geen antwoord',
      'no_vacancies.email': 'Geen vacatures beschikbaar',
      'non_eu.email': 'Non-EU',
    },
    EMPLOYEE_STATE_TRANSITION_PRESETS: getEmployeeStateTransitionPresets,
  },
  'donniebase-goingabroad-d7o45ih27a-ez.a.run.app': configs.goingabroad,
  'donniebase-goingabroad-etfgaan4vq-ez.a.run.app': configs.goingabroad,
  'donniebase-goingabroad.tapraise.dev': configs.goingabroad,
  'donniebase-goingabroad.tapraise.app': configs.goingabroad,
  localhost: {
    ERROR_REPORTING: false,
    REDUX_CONSOLE: true,
    ...configs.goingabroad,
  },
  'donniebase.nl': {
    HOSTED_DOMAIN: 'credo-marketing.nl',
    TITLE: 'Credo Fundraising',
    HQS_ACTIVE: ['Amsterdam', 'Amersfoort', 'Utrecht', 'Haarlem', 'Den Haag', 'Breda', 'Recruitment-team', 'Groningen', 'Amsterdam W. F. Hermansstraat'],
    HQS_INACTIVE: ['Hoofdkantoor', 'Eindhoven', 'Rotterdam', 'Assen', 'Delft'],
    ...configs.trust,
  },
  'base.werkenbijthesalescompany.nl': {
    TITLE: 'The Sales Company',
    HQS_ACTIVE: ['Alkmaar', 'Almere', 'Amsterdam', 'Arnhem', 'Den Bosch', 'Breda', 'Den Haag', 'Eindhoven', 'Enschede', 'Enschede NPL', 'Groningen', 'Heerenveen', 'Leeuwarden', 'Leiden', 'Maastricht', 'Nijmegen', 'Utrecht', 'Zwolle'],
    HQS_INACTIVE: [],
    ...configs.trust,
  },
  'base.werkenbijtrustmarketing.nl': {
    TITLE: 'Trust Marketing',
    HQS_ACTIVE: TRUST_HQS_ACTIVE,
    HQS_INACTIVE: ['Rotterdam D2D', 'Gent', 'Antwerpen', 'Zwolle', 'Dordrecht', 'Den Bosch', 'Second Nature'],
    ...configs.trust,
  },
  'base.werkenbijface2facemarketing.nl': {
    TITLE: 'Face2Face Marketing',
    HQS_ACTIVE: TRUST_HQS_ACTIVE,
    HQS_INACTIVE: [],
    ...configs.trust,
  },
  'base.herohive.de': configs.herohive,
  'donniebase-herohive.tapraise.app': configs.herohive
};

export const getEnvironment = (hostname, log = false) => {
  const environment = { ...envs.default, ...envs[hostname] };
  if (log) {
    console.log(environment);
  }
  window.document.title = environment.TITLE;
  return environment;
};

export default getEnvironment(window.location.hostname, true);
